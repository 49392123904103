<template>
  <div class="">
    <my-header-view
      title="Configuración"
      icon="mdi-office-building-cog-outline"
      text=""
    />
    <v-row
      :class="currentSystem === 'disagro' ? 'mt-14' : 'mt-4'"
      v-if="establishment"
    >
      <v-col
        cols="12"
        sm="4"
        :md="currentSystem === 'disagro' ? 6 : 4"
        :lg="currentSystem === 'disagro' ? 6 : 3"
        v-for="menu in menus"
        :key="menu.name"
      >
        <my-menu-card
          :title="menu.name"
          :description="menu.description"
          :url="menu.url"
          :img-url="menu.imgUrl ? menu.imgUrl : null"
          :icon="menu.icon ? menu.icon : null"
          :backgroundColor="menu.backgroundColor"
          :big-icon="true"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import myMenuCard from "../ui/myMenuCard";
import myHeaderView from "../ui/myHeaderView";
export default {
  components: {
    myMenuCard,
    myHeaderView,
  },
  data: () => ({
    currentSystem:
      process.env.VUE_APP_APP ||
      (window.location.host.includes("contamagica") ? "disagro" : "mynube"),
    menus: [
      {
        name: "Catálogos",
        imgUrl: "/img/menus/configuracion/catalogos.jpg",
        icon: "/img/icons/custom/disagro/documentos.png",
        url: "Catalogos",
        backgroundColor: "primary",
      },
      {
        name: "Flujo de procesos",
        imgUrl: "/img/menus/configuracion/procesos.jpg",
        icon: "/img/icons/custom/disagro/lista.png",
        url: "TProcesos",
        backgroundColor: "pla_c3",
      },
    ],
  }),
  methods: {},
  computed: {
    ...mapState({
      establishment: (state) => state.config.establishment,
    }),
  },
};
</script>
